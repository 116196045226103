import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import NavbarVertical from '../navbarverticle/NavbarVertical';
import NavbarTop from '../navbartop/NavbarTop';

const SideBar = ({ heading }) => {
    const [showMenu, setShowMenu] = useState(true);
    const isMdScreen = useMediaQuery({ query: '(min-width: 768px)' });

    // Function to handle window resize and set showMenu based on width
    const handleResize = () => {
        if (window.innerWidth <= 1200) {
            setShowMenu(true);
        }
    };

    useEffect(() => {
        // Set initial value based on window width
        handleResize();

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const ToggleMenu = () => {
        if (!isMdScreen) {
            setShowMenu(!showMenu);
        }
    };

    return (
        <div id="db-wrapper" className={`${showMenu ? '' : 'toggled'}`}>
            <div className="navbar-vertical navbar">
                <NavbarVertical
                    showMenu={showMenu}
                    onClick={(value) => setShowMenu(value)}
                    onLinkClick={ToggleMenu}
                />
            </div>
            <div id="page-content">
                <div className="header">
                    <NavbarTop
                        data={{
                            showMenu: showMenu,
                            SidebarToggleMenu: ToggleMenu
                        }}
                        heading={heading}
                    />
                </div>
                <Outlet />
            </div>
        </div>
    );
};

export default SideBar;