import { qs, qsOne } from '../utils/utils';
import api from '../utils/api';

export const getDoctor = async (data) => {
    try {
        const response = await api.get(`/doctor/getalldoctor?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}   

export const totalDoctor = async (data) => {
    try {
        const response = await api.get(`/doctor/getalldoctor`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateDoctor = async (data, dataset) => {
    try {
        const response = await api.put(`/doctor/update/${qsOne(data)}`, dataset);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getCount = async () => {
    try {
        const response = await api.get(`/get-count`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}