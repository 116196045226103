import React, { useEffect } from 'react'
import PrivacyPolicyform from '../components/privacypolicyform/PrivacyPolicyform'

const EditPrivacyPolicy = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Edit Privacy & Policy')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <PrivacyPolicyform />
                </div>
            </div>
        </>
    )
}

export default EditPrivacyPolicy