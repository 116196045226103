import React, { useEffect, useState } from 'react'
import style from './countbar.module.css'
import { totalDoctor } from '../../services/DashboardService';
import Loading from '../loading/Loading';
import message from '../../assets/motivational message.png'

const CountBar = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDoctorData();
    }, []);

    const getDoctorData = async () => {
        try {
            setLoading(true);
            const response = await totalDoctor();
            setData(response.total);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className='mt-sm-4 mt-2 row'>
            {loading && <Loading />}
            <div className={`col-xl-4 col-sm-6 pe-sm-3  pb-xl-0 pb-3`}>
                <div className={style.section}>
                    <div className={style.count1}>
                        <img src={message} alt="" />
                    </div>
                    <div>
                        <p className={style.content}>Total Doctors</p>
                        <h4 className={style.heading}>{data}</h4>
                    </div>
                </div>
            </div>
            {/* <div className={`col-xl-4 col-sm-6 px-xl-3 pb-xl-0 pb-3`}>
                <div className={style.section}>
                    <div className={style.count2}>
                    <img src={affirmation} alt="" />
                    </div>
                    <div>
                        <p className={style.content}>Total Affirmations</p>
                        <h4 className={style.heading}>{data?.affirmations}</h4>
                    </div>
                </div>
            </div>
            <div className={`col-xl-4 col-sm-6 ps-xl-3 pe-xl-0 pe-sm-3`}>
                <div className={style.section}>
                    <div className={style.count3}>
                    <img src={pod} alt="" />
                    </div>
                    <div>
                        <p className={style.content}>Total Pods</p>
                        <h4 className={style.heading}>{data?.pods}</h4>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default CountBar