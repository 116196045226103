import React, { useEffect, useState } from 'react';
import FAQForm from '../components/faqform/FAQForm';
import { getOneFAQ } from '../services/FAQService';
import { useParams } from 'react-router-dom';
import Loading from '../components/loading/Loading';

const EditFAQ = ({ setHeading }) => {
    const { id } = useParams();
    const [data, setData] = useState({ question: '', answer: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getOneFAQData();
        setHeading('Edit FAQ')
    }, [id]);

    const getOneFAQData = async () => {
        try {
            setLoading(true);
            const response = await getOneFAQ({ id });
            if (response?.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching FAQ data:', error);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div>
            {loading && <Loading/>}
            <div className="maindiv">
                <FAQForm initialData={data}/>
            </div>
        </div>
    );
};

export default EditFAQ;