import { qs, qsOne } from '../utils/utils';
import api from '../utils/api';

export const getPolicy= async (data) => {
    try {
        const response = await api.get(`/policy/getallpolicy`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOnePolicy = async (data) => {
    try {
        const response = await api.get(`/policy/getpolicy`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updatePolicy= async (data,params) => {
    try {
        const response = await api.put(`/policy/update`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const deleteOnePolicy = async (data) => {
    try {
        const response = await api.delete(`/policy/delete`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const addPolicy = async (data) => {
    try {
        const response = await api.post(`/policy/create`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}