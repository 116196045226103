import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const TransferForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState('');
  const [bankAccountId, setBankAccountId] = useState('');
  const [cardId, setCardId] = useState('');
  const [transferError, setTransferError] = useState(null);
  const [transferSuccess, setTransferSuccess] = useState(false);

  const handleTransfer = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      // Step 1: Call your backend to initiate the transfer
      const response = await fetch('http://localhost:4242/transfer-money', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: parseFloat(amount) * 100, // Convert to cents (Stripe amount format)
          currency: "usd",
          bankAccountId,
          cardId,
        }),
      });

      if (!response.ok) {
        throw new Error('Transfer failed');
      }

      const data = await response.json();
      console.log('Transfer successful:', data);

      setTransferSuccess(true);
    } catch (error) {
      console.error('Error:', error);
      setTransferError('Transfer failed');
    }
  };

  return (
    <form onSubmit={handleTransfer}>
      <label>
        Amount (USD):
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </label>
      <br />
      <label>
        Bank Account ID (for bank to card transfer):
        <input
          type="text"
          value={bankAccountId}
          onChange={(e) => setBankAccountId(e.target.value)}
        />
      </label>
      <br />
      <label>
        Card ID (for card to card transfer):
        <input
          type="text"
          value={cardId}
          onChange={(e) => setCardId(e.target.value)}
        />
      </label>
      <br />
      <button type="submit" disabled={!stripe}>
        Transfer Money
      </button>
      {transferError && <div>{transferError}</div>}
      {transferSuccess && <div>Transfer successful!</div>}
    </form>
  );
};

export default TransferForm;
