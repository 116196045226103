import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

const Session = () => {
  // Define your product details
  const product = {
    name: "Example Product",
    description: "This is an example product.",
    price: 2000, // Price in cents (e.g., 2000 cents = $20.00)
    quantity: 1
  };


  const makePayment = async () => {
    const stripe = await loadStripe("pk_test_51PbGaq2MdK9zKYLFnKik3fUASsC1IZrakJSmbbEVRrPQr4wTaISncfGsgOqWjRHZaIbFvjUfRBUYrwU30QocKpm60094E6MJcO");
    const body = { product, email: "super@gmail.com" };
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch("http://localhost:4242/create-checkout-session", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        console.log(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <button onClick={makePayment}>Pay Now</button>
    </div>
  );
};

export default Session;