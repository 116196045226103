import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './privacypolicydetails.module.css'
import { FiEdit } from 'react-icons/fi'
import { getPolicy } from '../../services/PrivacyPolicyService'
import Loading from '../loading/Loading'

const PrivacyPolicyDetails = () => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const params = {
        created_by: user?.id
    };

    useEffect(() => {
        getPolicyData();
    }, []); 

    const getPolicyData = async () => {
        try {
            setLoading(true);
            const response = await getPolicy(); 
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
        {loading && <Loading/>}
            <div className='mt-sm-4 mt-2 d-lg-flex mb-sm-3 mb-2 justify-content-end'>
                <button className={`btn ${style.editbutton} me-2`} onClick={() => navigate(`/privacy-policy/edit`)} >
                    <FiEdit className={style.edit} />
                </button>
            </div>
            <div className='bg-white p-4'> 
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
        </>
    )
}

export default PrivacyPolicyDetails