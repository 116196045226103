import React, { useEffect } from 'react'
import FAQDetails from '../components/faqdetails/FAQDetails'

const FAQ = ({ setHeading }) => {
    useEffect(() => {
        setHeading('FAQ')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <FAQDetails />
                </div>
            </div>
        </>
    )
}

export default FAQ